<template>
  <div class="background-container">
    <v-container>
      <v-card>
      <v-breadcrumbs :items="[
        { text: 'Solutions', exact: true, to: { name: 'solutions' } },
        { text: 'Challenge Ideas', disabled: true },
      ]" />
      <div :class="` ${$vuetify.breakpoint.xs?'':''}`">
        <h1>Corporate Fitness Challenge Ideas</h1>
        <p>
          Build and strengthen inter-team connections within your organization. Host team challenges to promote your divisions, facilities or country departments to work together towards a common goal.
        </p>
      </div>

    <h2 class="title">Ideas for engaging corporate challenges</h2>
    <div class="mb-8">
      <v-row>
        <v-col cols="12" md="4">
          <v-sheet elevation="4">
            <v-img src="https://sodisp.imgix.net/content/running-shoes-plus-watch.jpg?w=600" style="max-width:100%;" />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="8">
          <h3 class="subtitle-2">Daily Active Challenges</h3>
          <p>
            Set a daily active goal and promote a healthy dose of daily exercise. Badges can be awarded for each day the daily goal is met and also for streaks of consecutive days of activities.
          </p>
          <p>
            Did you know you can combine different challenge types in the same event? This allows you to create challenges which are engaging to all your employees, whether they are into running, walking, or yoga. 
          </p>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12" md="8">
          <h3 class="subtitle-2">Own the Map</h3>
          <p>
            Add some gamification to your challenge! We have divided the world into hexagon-shaped tiles and each activity is mapped onto these tiles and counts toward the local leaderboard. The individual or team with most distance in a tile owns that tile. Which team can collectively own most tiles? 
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <v-sheet elevation="4">
            <v-img src="https://sodisp.imgix.net/blog/feature-tile-challenge.jpg?w=600" style="max-width:100%;" />
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>
      <v-row>
        <v-col cols="12" md="4">
          <v-sheet elevation="4">
            <v-img src="https://sodisp.imgix.net/content/group-workout.jpg?w=600" style="max-width:100%;" />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="8">
          <h3 class="subtitle-2">Team vs Team</h3>
          <p>
            Divide your employees into teams and set a goal for each team. The first team reaching the goal wins the challenge!
          </p>
          <p>
            Progress can be tracked in real-time on our virtual course maps and leaderboards. Social result allow participants to cheer each other on by leaving comments or posting selfies.
          </p>
        </v-col>
      </v-row>
      <v-divider class="my-8"/>

      <h2 class="title">Why wait? Let's Go!</h2>
      <v-row>
        <v-col cols="12" sm="6">
          <v-btn large block color="primary" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn large block outlined color="primary" to="/features">Explore All Features</v-btn>
        </v-col>
      </v-row>
    </div>
  
    <div class="pb-6 pb-md-12">
      <v-row >
        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(0)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-watch-fitness</v-icon></v-card-text>
            <v-card-title class="headline">All major fitness trackers supported</v-card-title>
            <v-card-text>
              Allow your athletes to use the app or device they already love! 
              We support {{ providers.getSupportedProviders().join(', ') }}, and Apple Watch.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(1)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-user-headset</v-icon></v-card-text>
            <v-card-title class="headline">Full-Service support available</v-card-title>
            <v-card-text>
              Choose between our self-service or full-service packages to select the best option for your organization. 
              With the full-service package we take care of the initial setup and support so you can keep focussed on running your business!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(2)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-medal</v-icon></v-card-text>
            <v-card-title class="headline">Everyone wins!</v-card-title>
            <v-card-text>
              We offer various challenge types to promote physical and mental wellbeing within teams and organizations.
              Award Badges to keep your employees motivated and active throughout the entire challenge!
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :color="$helpers.getGradientColor(3)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-shield-check</v-icon></v-card-text>
            <v-card-title class="headline">Private and secure</v-card-title>
            <v-card-text>
              We understand that health and fitness data of employees needs to be carefully protected. We have various options to configure which devices and options you want to offer to your employees.
            </v-card-text>
          </v-card>
        </v-col>
        
      </v-row>
    </div>

    <div class="grey lighten-4">
    <v-container class="mb-8">
      <v-row>
        <v-col class="pb-8">
          <h3 class="title">Integration with all major fitness trackers</h3>
          <SectionIntegrations />

          <v-btn large block color="primary" class="mt-8" to="/features">Explore All Features</v-btn>
        </v-col>
      </v-row>
    </v-container>
    </div>
  
    <div id="page-customers" class="mt-0 white--text mx-n5 mb-8">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              Our platform is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>

          </v-col>
        </v-row>
      </div>
    </div>

    <div class="">
      <h2 class="subtitle">Solutions for Connected Fitness Challenges</h2>
      <v-list elevation="2" class="my-8 py-0">
        <v-list-item v-for="(item, idx) in navData['solutions-menu-cofi'].filter(x => x.highlight)" :key="idx" :to="item.to" >
          <v-list-item-icon>
            <v-icon size="25">fadl fa {{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-action>
            <v-icon size="25">fal fa-chevron-circle-right</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

    </div>    

    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
      </v-card>
    </v-container>
  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'
import navData from '@/data/nav.json'
import providers from '@/util/oauthProviders.js'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      navData: navData,
      providers: providers,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }

  .background-container { background-image: url(https://sodisp.imgix.net/content/team-building.jpg); }
</style>